/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */

import { match, P } from 'ts-pattern';
import { useMediaQuery } from 'usehooks-ts';
import { createContext, memo, useContext, useEffect, useState } from 'react';
import { sortBy } from 'remeda';

import { Country, Event, Venue } from './types';
import { Flag, suggestionFormUrl } from './common';
import { formatISOWithOptions } from 'date-fns/fp';

export const EventCard = memo(function EventCard({ event }: { event: Event }) {
	const { setModalEvent } = useContext(EventModalContext);
	const cantHover = useMediaQuery('(hover: none)');
	const narrowView = useMediaQuery('(max-width: 39rem)');
	const onTapEvent = (cantHover || narrowView) ? () => setModalEvent(event) : undefined;

	return (
		<div key={event.name} onClick={onTapEvent}>
			<article className="event" data-cancelled={event.cancelled ? true : null}>
				<Caption name={event.name} country={event.country} />
				<Image url={event.cover} />
				<EventSections event={event} />
			</article>
		</div>
	);
});

export const EventModalContext = createContext<{
	modalEvent: Event | null,
	setModalEvent: (event: Event | null) => void,
}>(undefined!);

export const EventModalContextProvider = ({ children }: { children: React.ReactNode }) => {
	const [modalEvent, setModalEvent] = useState<Event | null>(null);

	const set = (event: Event | null) => {
		if (event) { window.history.pushState({ event }, ''); }
		setModalEvent(event);
	};

	useEffect(() => {
		const listener = (event: PopStateEvent) => { setModalEvent(null); };
		window.addEventListener('popstate', listener);
		return () => window.removeEventListener('popstate', listener);
	}, [/* once */]);

	return (
		<EventModalContext.Provider value={{ modalEvent, setModalEvent: set }}>
			{children}
		</EventModalContext.Provider>
	);
};

export const EventModal = () => {
	const { modalEvent: event, setModalEvent } = useContext(EventModalContext);

	if (!event) { return null; }

	const onClose = () => setModalEvent(null);

	return <>
		<div className="backdrop" onClick={onClose} />
		<article className="dialog event">
			<header className="caption">
				<Flag country={event.country} />
				<span className="name">{event.name}</span>
				<button onClick={onClose}>&times;</button>
			</header>
			<Image url={event.cover} />
			<EventSections event={event} />
		</article>
	</>;
};

function EventSections({ event }: { event: Event }) {
	const videos = event.links.filter(({ kind }) => kind === 'videos');

	return <>
		{Object.keys(event.facebook).map((fb_id, i) => (
			<FacebookLink key={`facebook-${i}`} fbId={fb_id} info={event.facebook[fb_id]} />
		))}

		{event.links.filter(({ kind }) => kind === 'results').map(({ kind, url }, i) => (
			<SingleLink key={`link-${kind}-${i}`} code={kind} title="Results" url={url} />
		))}

		{videos.length > 1 ? (
			<section key="videos" className="link">
				<b>Videos:</b>
				<ol>
					{videos.map(({ kind, url, title }, i) => (
						<li key={i}><a href={url} title={url}>{title || url}</a></li>
					))}
				</ol>
			</section>
		) : videos.map(({ kind, url }, i) => (
			<SingleLink key="videos" code={kind} title="Videos" url={url} />
		))}

		{(event.people && !!event.people.length) && (
			<People people={event.people} />
		)}

		{sortBy(Object.keys(event.venues), venueOrder).map((key: string) => (
			<Location key={key} venue={event.venues[key]} />
		))}

		{event.links.filter(({ kind }) => !isSpecialLinkKey(kind)).map(({ kind, url }, i) => (
			<SingleLink key={`link-${kind}-${i}`} code={kind} title="Website" url={url} />
		))}

		<section className="suggest">
			<a href={suggestFormLink(event)} target="_blank">Suggest a correction for this event</a>
		</section>

		{!!eventTags(event).length && (
			<Tags tags={eventTags(event)} />
		)}
	</>;
}

const People = ({ people }: { people: string[] }) => (
	<section className="people">
		<ul>
			{people.map(name => (
				<li key={name}>{name}</li>
			))}
		</ul>
	</section>
);

const Caption = ({ country, name }: { country: Country, name: string }) => (
	<header className="caption">
		<Flag country={country} />
		<span className="name">{name}</span>
	</header>
);

const Image = ({ url }: { url: string | null }) => (
	<section className="image">
		{url && (
			<img src={url} onError={({ target }) => {
				(target as any).onerror = null;
				(target as any).src = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
			}} />
		)}
	</section>
);

const Tags = ({ tags }: { tags: string[] }) => (
	<section className="tags">
		<ul>
			{tags.map((tag) => (
				<li key={tag} className={tag}>{tag}</li>
			))}
		</ul>
	</section>
);

function suggestFormLink(event: Event): string {
	const sunday = formatISOWithOptions({ representation: 'date' }, event.sunday);
	return suggestionFormUrl + '?usp=pp_url&entry.1103812507=' + encodeURIComponent(sunday + ' ' + event.name);
}

function venueOrder(key: string): string {
	const index = ["fb2", "wsdc", "facebook2", "fb2-raw", "facebook"].indexOf(key);
	return index >= 0 ? `__${index}` : key
}

function isSpecialLinkKey(key: string): boolean {
	return key.startsWith('facebook:event') || key === 'results' || key === 'videos';
}

function eventTags(event: Event): string[] {
	const { links, hotel, wsdc, cancelled } = event;
	const tags = [];
	if (links && links.some(({ kind }) => kind === 'videos')) {
		tags.push('videos');
	}
	if (links && links.some(({ kind }) => kind === 'results')) {
		tags.push('results');
	}
	if (hotel) {
		tags.push('hotel');
	}
	if (wsdc) {
		tags.push('wsdc');
	}
	if (cancelled) {
		tags.push('cancelled');
	}
	return tags;
}

const FacebookLink = ({ fbId, info }: { fbId: string, info: { going?: number, responded?: number } }) => (
	<section className="link">
		<a href={`http://fb.com/events/${fbId}`}>Facebook</a>
		{match(info)
			.with({
				going: P.number,
				responded: P.optional(P.nullish),
			}, ({ going }) => <>: {going} going</>)
			.with({
				going: P.optional(P.nullish),
				responded: P.number,
			}, ({ responded }) => <>: {responded} responded</>)
			.with({
				going: P.number,
				responded: P.number,
			}, ({ going, responded }) => <>: {responded} responded, {going} going</>)
			.otherwise(() => null)}
	</section>
);

const SingleLink = ({ code, title, url }: { code: string, title: string, url: string }) => (
	<section className="link">
		<b>{title}:</b> <a href={url} title={url}>{url}</a>
	</section>
);

const Location = ({ venue: { name, address, url } }: { venue: Venue }) => {
	const tokens: Array<null | string | React.ReactElement<any>> = [name, address].filter(t => !!t);
	if (!tokens.length) { return null; }
	if (url) { tokens[0] = <a href={url}>{tokens[0]}</a>; }

	return (
		<section className="location">
			<b>Location: </b>
			{tokens[0]}
			{(tokens.length > 1) && (
				<>, {tokens[1]}</>
			)}
		</section>
	);
};
