'use client';

import { useEffect, useState, useContext, useRef, useCallback, RefObject } from 'react'
import { range } from 'remeda';
import classNames from 'classnames';

import { SettingsContext } from '../components/settings';
import { Flag, suggestionFormUrl, thisSunday, thisYear } from '../components/common';
import { DataContext } from '../components/data';
import { $Suggestions, Filters, QueryInput } from '../components/search';
import { CountryTree } from '../components/region-tree';
import { ZoneCode } from './countries';
import { SearchContextProvider } from './search-context';
import { ScrollContext } from './scroll-context';

export const TopBar = () => {
    const { scrollToSunday } = useContext(ScrollContext);

    return (
        <SearchContextProvider>
            <nav>
                <a className="logo" href="?countries=all">Weekenders</a>

                <section className="search">
                    <RegionPicker />
                    <QueryInput />
                    <Filters />
                    <$Suggestions />
                    <YearPicker />
                </section>

                <section className="buttons">
                    <button className="goto-today" onClick={() => scrollToSunday(thisSunday)}>
                        TODAY
                    </button>
                    <Info />
                </section>
            </nav>
        </SearchContextProvider>
    );
};

function useDropDown(close: () => void): RefObject<HTMLElement> {
    const dropDown = useRef<HTMLElement | null>(null);

    const closeHandler = useCallback((e: MouseEvent) => {
        if (!dropDown.current?.contains(e.target as Node)) { close(); }
    }, [dropDown, close]);

    useEffect(() => {
        document.addEventListener('mousedown', closeHandler);
        return () => document.removeEventListener("mousedown", closeHandler);
    }, [closeHandler]);

    return dropDown;
};

const YearPicker = () => {
    const { firstYear, lastYear } = useContext(DataContext);
    const { setSettings, settings } = useContext(SettingsContext);
    const [showYears, setShowYears] = useState(false);

    const dropDown = useDropDown(() => setShowYears(false));

    return (
        <section className="years" ref={dropDown}>
            <button className="show-years" onClick={() => setShowYears(!showYears)}>
                {settings.year ?? 'ALL YEARS'}
            </button>

            <section className={classNames(['years', showYears && 'show'])}>
                <ul>
                    {range(firstYear, lastYear + 1).map(year => (
                        <li key={year} className={classNames({ selected: year === settings.year })} onClick={() => {
                            setSettings({ type: 'set-year', year });
                            setShowYears(false);
                        }}>{year}</li>
                    ))}
                </ul>

                <input
                    type="checkbox" id="collapsed"
                    checked={settings.year === null}
                    onChange={e => {
                        setSettings({ type: 'set-year', year: e.target.checked ? null : thisYear });
                        setShowYears(false);
                    }} />
                <label htmlFor="collapsed">Show all years at once</label>
            </section>
        </section >
    );
};

const RegionPicker = () => {
    const dropDown = useDropDown(() => setShowRegions(false));
    const [showRegions, setShowRegions] = useState(false);

    return (
        <section className="regions" ref={dropDown}>
            <button className="show-regions" onClick={() => setShowRegions(!showRegions)}>
                <CompactRegionString />
            </button>

            <section className={classNames(['regions', showRegions && 'show'])}>
                <CountryTree />
            </section>
        </section>
    );
};

const Info = () => {
    const dropDown = useDropDown(() => setShowInfo(false));
    const [showInfo, setShowInfo] = useState(false);

    return (
        <section className="info" ref={dropDown}>
            <button className="show-info" onClick={() => setShowInfo(!showInfo)}>INFO</button>

            <section className={classNames(['info-panel', showInfo && 'show'])}>
                <section>
                    Event information is collected automatically and may contain errors.
                    Always double-check with event websites.
                </section>

                <section>
                    Please use <a href={suggestionFormUrl} target='_blank'>this form</a>
                    {" "}to suggest new events, corrections and updates, or just contact me directly
                    {" "}<a href="https://www.facebook.com/leastfixedpoint" target='_blank'>on Facebook</a> or
                    {" "}<a href="mailto:tymur.porkuian@gmail.com">by e-mail</a>.
                </section>

                <section>
                    Check our <a href="privacy-policy.html">privacy policy</a>.
                </section>
            </section>
        </section>
    );
};

const CompactRegionString = () => {
    const { settings: { regionFilter } } = useContext(SettingsContext);

    if (regionFilter.includesAll()) {
        return 'WORLD';
    }

    const zone = regionFilter.getSingleZone();
    if (zone !== null) {
        return compactZoneNames[zone.code];
    }

    const country = regionFilter.getSingleCountry();
    if (country) {
        return <span><Flag country={country} /> {country.code.toUpperCase()}</span>;
    }

    if (regionFilter.getCountriesCount() === 0) {
        return <span className="warning">0 COUNTRIES</span>;
    }

    return regionFilter.getCountriesCount() + ' COUNTRIES';
};

const compactZoneNames: { [code in ZoneCode]: string } = {
    africa: 'AFRICA',
    antarctica: 'ANTARCTICA',
    apac: 'APAC',
    eme: 'EU+',
    northam: 'N.AM',
    southam: 'S.AM',
    other: 'OTHER',
};
