import { getYear, parseISO } from 'date-fns';
import { firstBy, identity, map, mapValues, maxBy, pipe, sort, sortBy, values } from 'remeda';

import { COUNTRIES, isCountryCode, ZoneCode, ZONES } from './countries';
import { Input, InputEvent, Data, Event, Weekend, Regions, Country, Zone } from './types';
import { addDays, formatISOWithOptions, isAfter, nextSunday, subDays } from 'date-fns/fp';
import { thisYear } from './common';
import { createContext } from 'react';

export const DataContext = createContext<Data>(inputToData({ people: [], events: [], countries: [] }));

export function inputToData(input: Input): Data {
    const events: Event[] = [];
    const weekends = new Map<number, Weekend>();
    let firstYear = thisYear;
    let lastYear = thisYear;
    
    const zoneMap: Map<ZoneCode, Zone> = new Map();
    
    for (const inputEvent of input.events) {
        let cover = inputEvent.cover || null;
        if (cover) {
            cover = cover.replace('https://weekenders.s3.eu-central-1.amazonaws.com/', '');
            cover = `https://d3q8bcwlla5p80.cloudfront.net/288x120/${cover}`
        }

        const countryCode = isCountryCode(inputEvent.country_code) ? inputEvent.country_code : 'unknown';
        const { zoneCode, name: countryName } = COUNTRIES[countryCode];
        let zone = zoneMap.get(zoneCode);
        if (!zone) {
            zoneMap.set(zoneCode, zone = { code: zoneCode, name: ZONES[zoneCode].name, countries: [], eventCount: 1 });
        } else {
            zone.eventCount++;
        }
        let country = zone.countries.find(c => c.code === countryCode);
        if (!country) {
            zone.countries.push(country = { name: countryName, code: countryCode, zone, eventCount: 1 });
        } else {
            country.eventCount++;
        }

        const event: Event = {
            name: inputEvent.name!,
            cover: cover,
            facebook: inputEvent.facebook || {},
            links: (inputEvent.links || []).map(({ kind, url, title }) => ({ kind, url, title: title ?? url })),
            people: inputEvent.people || [],
            venues: mapValues(
                inputEvent.venues || {},
                ({ name = null, address = null, url = null }, code) => ({ code, name, address, url })),
            sunday: parseISO(inputEvent.sunday),
            country,
            hotel: inputEvent.hotel || false,
            wsdc: inputEvent.wsdc || false,
            cancelled: inputEvent.cancelled || false,
            hasVideos: (inputEvent.links || []).some(({ kind }) => kind === 'videos'),
            hasResults: (inputEvent.links || []).some(({ kind }) => kind === 'results'),
        };
        
        events.push(event);

        const sunday = event.sunday;
        
        const ws = weekends.get(sunday.getTime()) || { sunday, events: [] };
        ws.events.push(event);
        weekends.set(sunday.getTime(), ws);
        
        const year = getYear(sunday);
        if (year > lastYear) lastYear = year;
        if (year < firstYear) firstYear = year; 
    }

    for (const ws of weekends.values()) {
        ws.events = sortEvents(ws.events);
    }
    
    const zones = [...zoneMap.values()];
    sort(zones, (z1, z2) => z2.eventCount - z1.eventCount);
    for (const zone of zoneMap.values()) {
        sort(zone.countries, (c1, c2) => c2.eventCount - c1.eventCount);
    }
    
    const countries = [...zoneMap.values()].flatMap(z => z.countries)
    const regions = {
        countries,
        countryMap: new Map(countries.map(c => [c.code, c])), 
        zones,
        zoneMap: new Map(zones.map(z => [z.code, z])),
    };

    return {
        events,
        weekends,
        people: input.people,
        regions,
        firstYear,
        lastYear,
    };
}

export function sundaysForYear(year: number): Date[] {
    const sundays = [];
    const first = pipe(new Date(year, 0, 1), subDays(13), nextSunday);
    const last = pipe(new Date(year, 11, 31), addDays(6), nextSunday);
    for (let sunday = first; !isAfter(last, sunday); sunday = nextSunday(sunday)) {
        sundays.push(sunday);
    }
    return sundays;
}

function sortEvents(events: Event[]): Event[] {
    return events.sort((e1, e2) => {
        const going1 = firstBy([0,
            ...pipe(e1.facebook, Object.values, map(i => i.going)),
            ...pipe(e1.facebook, Object.values, map(i => i.responded)),
        ], [identity, 'desc']);
        const going2 = firstBy([0,
            ...pipe(e2.facebook, Object.values, map(i => i.going)),
            ...pipe(e2.facebook, Object.values, map(i => i.responded)),
        ], [identity, 'desc']);
        if (going1 > going2) { return -1; }
        if (going1 < going2) { return 1; }
        return e1.name > e2.name ? 1 : -1;
    });
}
