import { setISODay, startOfDay } from "date-fns/fp";
import { pipe } from 'remeda';
import { RefObject, useCallback, useEffect, useRef } from "react";

import { Country } from "./types";

export const thisSunday = pipe(new Date(), startOfDay, setISODay(7));
export const thisYear = new Date().getFullYear();

export const Flag = ({ country }: { country: Country }) => (country.code == 'unknown') ? null : (
    <span className={`fi fi-${country.code}`} title={country.name} />
);

export function useDropDown(close: () => void): RefObject<HTMLElement> {
    const dropDown = useRef<HTMLElement | null>(null);

    const closeHandler = useCallback((e: MouseEvent) => {
        if (!dropDown.current?.contains(e.target as Node)) { close(); }
    }, [dropDown, close]);

    useEffect(() => {
        document.addEventListener('mousedown', closeHandler);
        return () => document.removeEventListener("mousedown", closeHandler);
    }, [closeHandler]);

    return dropDown;
}

export const suggestionFormUrl = 'https://docs.google.com/forms/d/e/1FAIpQLScVMm29Qvjs7mYeBQ7APuDU2W2KWoVav-tMAZGFv0ckT9zCiQ/viewform';
